import { zodResolver } from '@hookform/resolvers/zod'
import { XCircle } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import AddSVG from '../../../../assets/icons/add.svg'
import AddIconSVG from '../../../../assets/icons/fi-rs-add.svg'
import { createAnArticle, getUrlMetadata } from '../../../../services/article'
import { URLMetadata } from '../../../../services/article.types'
import { cn } from '../../../../utils/cn'
import debounce from '../../../../utils/debounce'
import {
  getDomainFromUrl,
  removeQueryParamsFromSrc,
} from '../../../../utils/url'
import Button from '../../../Button'
import TopicSelectDialogContent from '../../../CreateContrastDialog/TopicSelectDialogContent'
import Loading from '../../../Loading'
import RenderFieldError from '../../../RenderFieldError'
import { Dialog, DialogContent, DialogTrigger } from '../../../shadcn/dialog'
import { Input } from '../../../shadcn/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../shadcn/select'
import EntryDialog from './EntryDialog'
import PostInputField from './PostInputField'
import QuoteInputField from './QuoteInputField'
import URLPreview from './URLPreview'
import { SchemaType, schema } from './helper'
import { getAllowedPostTypes } from '../../../../utils/permissions'
import { toast } from 'sonner'
import { getMyLocation, getOneTopic } from '../../../../services/topic'
import DropDownSVG from '../../../../assets/icons/drop-down.svg'
import css from './styles.module.css'
import LoadingOverlay from '../../../LoadingOverlay'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  cb?: () => void
  parentTopicId?: string
}

export default function AddArticle(props: DialogProps) {
  const { open, setOpen } = props

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="max-w-[600px] max-h-screen overflow-y-auto overflow-x-visible mx-mobile:h-[100dvh]"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

function Content({ open, setOpen, cb, parentTopicId }: DialogProps) {
  const [topicOpen, setTopicOpen] = useState<boolean>(false)
  const [topic, setTopic] = useState<any | null>(null)
  // const [locationObj, setLocationObj] = useState<any | null>(null)
  const [location, setLocation] = useState<any | null>(null)
  // const [inputValue, setInputValue] = useState('')
  const [metadataLoading, setMetadataLoading] = useState(false)
  const [urlMetadata, setUrlMetadata] = useState<any | null>(null)
  const [organizationId, setOrganizationId] = useState('no-org')

  // console.log('🚀 ~ Content ~ organizationId:', organizationId)
  const [entryOpen, setEntryOpen] = useState(false)

  const user = useSelector((state: any) => state.login.user)

  const form = useForm<SchemaType>({
    resolver: zodResolver(schema),
    // mode: 'onChange',
  })
  // const form = useForm()

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = form

  const postsArr = watch('posts')
  const topicId = watch('topicId')
  const url = watch('url')

  const postFieldArray = useFieldArray({
    control,
    name: 'posts',
  })

  const [isLoading, setIsLoading] = useState(false)
  const uuidRegexLocal =
    /local\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/

  useEffect(() => {
    if (parentTopicId) {
      if (!parentTopicId.match(uuidRegexLocal)) {
        setIsLoading(true)
        getOneTopic(parentTopicId)
          .then((data) => {
            setIsLoading(false)
            setValue('topicId', data.id)
            setTopic({
              label: data.title,
              value: data.id,
            })
          })
          .catch(() => {
            setIsLoading(false)
          })
      } else {
        setValue('topicId', 'local')
        setIsLoading(true)
        getMyLocation()
          .then((data) => {
            setIsLoading(false)
            setLocation({
              label: data.address,
              id: data.id,
            })
          })
          .catch(() => {
            setIsLoading(false)
          })
      }
    }
  }, [parentTopicId])

  const debouncedOnChange = useCallback(
    debounce((value) => {
      if (value) {
        setMetadataLoading(true)
        getUrlMetadata(value)
          .then((res) => {
            setUrlMetadata({
              // imageSRC: removeQueryParamsFromSrc(
              //   res?.['twitter:image:src'] || res?.['og:image']
              // ),
              imageSRC: res?.resolvedImage,
              // imageSRC: res?.['twitter:image:src'] || res?.['og:image'],
              title: res?.['twitter:title'] || res?.['og:title'] || res?.title,
              domainName: getDomainFromUrl(res?.requestUrl),
              favicon:
                res.favicon ||
                `${process.env.REACT_APP_BASE_URL}/logo-white-border.png`,
            })
          })
          .catch((err) => {
            toast.error('Unable to reach the URL.')
            setUrlMetadata(null)
            console.log({ err })
          })
          .finally(() => {
            setMetadataLoading(false)
          })
      }
    }, 300),
    []
  )

  const currentUser = useSelector((state: any) => state.login.user)

  const allowedPostTypes = getAllowedPostTypes(
    currentUser?.roles?.permissions?.postCreation,
    ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']
  )

  const [disableCreateArticle, setDisableCreateArticle] = useState(false)
  const [disableButton, setDisableButton] = useState(true)

  const createArticle = async (values:any) => {
    createAnArticle(values)
        .then((res) => {
          cb && cb()
          toast.success('Article Created')
        })
        .catch(() => {
          toast.error('Something went wrong')
        })
        .finally(() => {
          setDisableCreateArticle(false)
          setOpen(false)
        })
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <FormProvider {...form}>
        <div className="">
          <p>
            Create{' '}
            <span className="font-bold underline underline-offset-4">
              ARTICLE
            </span>
          </p>

          <div className="mt-5">
            <Dialog open={topicOpen} onOpenChange={setTopicOpen}>
              <DialogTrigger asChild>
                <button
                  className={cn('cursor-pointer w-full text-left text-[11px]')}
                >
                  {!topic && !location && (
                    <div className="bg-line p-3 rounded-md text-sm h-[64px]">
                      Select location or topic...
                    </div>
                  )}

                  {topic && (
                    <div className="bg-line p-3 rounded-md text-sm flex justify-between items-center">
                      <p className="flex flex-col">
                        <span className="">Topic</span>
                        <span className="font-semibold">{topic.label}</span>
                      </p>

                      <XCircle
                        size={20}
                        className="hover:text-red-500 transition-all cursor-pointer w-4 flex-shrink-0"
                        onClick={() => {
                          setTopic(null)
                        }}
                      />
                    </div>
                  )}

                  {location && (
                    <div className="bg-line p-3 py-4 rounded-md text-sm flex justify-between items-center">
                      {/* <p className="">
                        <span className="text-border ">Location:</span>{' '}
                        <span>{locationObj.display_name}</span>
                      </p> */}

                      <p className="flex flex-col">
                        <span className="">Location</span>
                        <span className="font-semibold">{location.label}</span>
                      </p>

                      <XCircle
                        size={20}
                        className="hover:text-red-500 transition-all cursor-pointer w-4 flex-shrink-0"
                        onClick={() => {
                          setLocation(null)
                        }}
                      />
                    </div>
                  )}
                </button>
              </DialogTrigger>
              <DialogContent className="p-0 w-[518px] mx-sm:w-screen">
                <TopicSelectDialogContent
                  onSelectLocation={(value) => {
                    setValue('topicId', 'local')
                    setLocation(value)
                    setTopic(null)
                    setTopicOpen(false)
                  }}
                  onSelectTopic={(value) => {
                    // console.log('🚀 ~ Content ~ value:', value)
                    setValue('topicId', value.value)
                    setTopic(value)
                    setLocation(null)
                    setTopicOpen(false)
                  }}
                />
              </DialogContent>
            </Dialog>
          </div>

          <RenderFieldError error={errors.topicId} />

          <div
            className={cn('mt-5', {
              'invisible opacity-0': !topicId && !location,
            })}
          >
            <Select
              value={organizationId}
              defaultValue="no-org"
              onValueChange={(v) => {
                setOrganizationId(v)
                setValue('organizationId', v)
              }}
            >
              <SelectTrigger
                className={cn(
                  'h-auto w-full [&>span]:w-full border-0 p-[2px] [&>svg]:hidden [&>span]:h-fit [&>span]:min-h-[40px]',
                  css.selected
                )}
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="w-full " align="start">
                <SelectItem
                  value="no-org"
                  className="flex-row [&>span:nth-child(1)]:hidden [&>span:nth-child(2)]:w-full p-1"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col items-start">
                      <p className="text-sm flex items-center gap-1">
                        {user?.name}

                        <img
                          src={DropDownSVG}
                          className="invisible w-[18px] h-[18px]"
                        />
                      </p>
                      <div className="flex gap-1 text-[14px]">
                        <span className="text-border">{user?.roles?.name}</span>
                      </div>
                    </div>
                    <div className="flex gap-1 items-center">
                      <img
                        src={user?.imageUrl}
                        className="w-[35px] h-[35px] rounded-full object-cover"
                      />
                    </div>
                  </div>
                </SelectItem>
                {user?.organizationUsers?.map((x: any) => {
                  return (
                    <SelectItem
                      key={x.id}
                      value={x.id.toString()}
                      className="flex-row [&>span:nth-child(1)]:hidden [&>span:nth-child(2)]:w-full p-1"
                    >
                      <div className="flex justify-between w-full">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center gap-1">
                            <p className="text-sm">{user.name}</p>
                            {/* <ChevronDown size={18} className="hidden" /> */}
                            <img
                              src={DropDownSVG}
                              className="invisible w-[18px] h-[18px]"
                            />
                          </div>
                          <div className="flex gap-1 text-[14px]">
                            <span className="font-bold">{x?.name}</span>
                            <span className="text-border">
                              {user?.roles?.name}
                            </span>
                          </div>
                          <span className="text-[14px] text-border">
                            {x?.type}
                          </span>
                        </div>
                        <div className="flex gap-1 items-center">
                          <img
                            src={x?.imageUrl}
                            className="w-[35px] h-[35px] rounded-full object-contain"
                          />
                          <img
                            src={user?.imageUrl}
                            className="w-[35px] h-[35px] rounded-full object-cover"
                          />
                        </div>
                      </div>
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </div>

          <Controller
            control={control}
            name="url"
            render={({ field }) => {
              return (
                <div
                  className={cn('mt-5', {
                    invisible: !topicId,
                  })}
                >
                  <Input
                    value={field.value}
                    className={cn(
                      'h-[29px] border-line placeholder:text-line placeholder:text-[11px] placeholder:italic text-[14px]',
                      {
                        hidden: urlMetadata,
                      }
                    )}
                    placeholder="Enter article URL"
                    onChange={(e) => {
                      // setValue('url', e.target.value)
                      field.onChange(e.target.value)
                      debouncedOnChange(e.target.value)
                    }}
                  />

                  <RenderFieldError error={errors?.url} />
                </div>
              )
            }}
          />

          {metadataLoading && (
            <div className="flex justify-center mt-2">
              <Loading />
            </div>
          )}

          {urlMetadata && (
            <URLPreview
              onClickClose={() => {
                setValue('url', '')
                setUrlMetadata(null)
              }}
              imageSRC={urlMetadata?.imageSRC}
              title={urlMetadata?.title}
              domainName={urlMetadata?.domainName}
            />
          )}

          <hr className="h-[1px] bg-border mt-[22px]" />

          <div className="">
            {postFieldArray.fields.map((f, index) => {
              return (
                <Controller
                  key={f.id}
                  control={control}
                  name={`posts.${index}.data`}
                  render={({ field }) => {
                    // console.log('🚀 ~ field:', field)

                    if (f.type === 'post') {
                      return (
                        <PostInputField
                          allowedPostTypes={allowedPostTypes || []}
                          index={index}
                          onClickClose={(i) => {
                            postFieldArray.remove(i)
                          }}
                          setDisableButton={setDisableButton}
                        />
                      )
                    } else {
                      return (
                        <QuoteInputField
                          index={index}
                          onClickClose={(i) => {
                            postFieldArray.remove(i)
                          }}
                        />
                      )
                    }
                  }}
                />
              )
            })}
          </div>

          {/* {errors?.posts?.root && (
          <p className="text-[14px] text-red-500 font-semibold">
            {errors?.posts?.root.message}
          </p>
        )} */}

          <RenderFieldError error={errors?.posts?.root} />

          {postsArr?.length === 0 && (
            <div
              className={cn(
                'flex gap-1 items-center text-[14px] font-bold text-[#2443B2] mt-7 justify-center cursor-pointer',
                {
                  invisible: !url,
                }
              )}
              onClick={() => {
                setEntryOpen(true)
              }}
            >
              <img src={AddIconSVG} className="w-4 h-4" />
              Add a post
            </div>
          )}

          <div className="flex items-center justify-between mt-5">
            <Button
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>

            <div className="flex gap-[5px] items-center">
              <img
                src={AddSVG}
                className={cn('h-6 w-6 cursor-pointer', {
                  invisible:
                    !url || postsArr.length < 1 || postsArr.length >= 5,
                })}
                onClick={() => {
                  setEntryOpen(true)
                }}
              />

              <Button
                // disabled
                 disabled={disableButton}
                onClick={async () => {
                    await createArticle({
                      "location": location,
                      "posts": watch('posts'),
                      "topicId": watch('topicId'),
                      "url": watch('url'),
                      ...urlMetadata
                    })


                }
                //   async (values) => {
                //   console.log("values:::::::",values)
                //      createAnArticle({
                //       location: location,
                //       ...values,
                //       ...urlMetadata,
                //     })
                //       .then((res) => {
                //         cb && cb()
                //         toast.success('Article Created')
                //       })
                //       .catch((e) => {
                //         console.error("e:::::",e)
                //         toast.error('Something went wrong')
                //       })
                //       // .finally(() => {
                //       //   setDisableCreateArticle(false)
                //       //   setOpen(false)
                //       // })
                // }}
                }
              >
                Create
              </Button>
            </div>
          </div>
        </div>
        <EntryDialog
          allowedPostTypes={allowedPostTypes || []}
          open={entryOpen}
          setOpen={setEntryOpen}
          onSelectItem={(selectedItem) => {
            if (selectedItem === 'quote') {
              postFieldArray.append({
                type: 'quote',
              })
            } else {
              postFieldArray.append({
                type: 'post',
                data: {
                  postType: selectedItem,
                },
              })
            }

            setEntryOpen(false)
          }}
        />
      </FormProvider>
    </>
  )
}
